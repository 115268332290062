<template>
  <div class=myLikes>
    <van-nav-bar title="我的喜欢"  left-arrow fixed @click-left="goBack" class="nav">
    </van-nav-bar>




      <van-pull-refresh v-model="data.loading" @refresh="onRefresh" class="refresh-body">
        <van-list  v-if=" data.firstShow || data.list.length  > 0" v-model:loading="data.loading" v-model:error="data.error" :finished="data.finished" finished-text="已经到底了"
                   error-text="加载出错,请重新加载" @load="onLoad">

      <div v-for="item in data.list" class="video-item" @click="toPlayVideo(item)">
        <div class="top-video">
          <van-image radius="8px"  class="video-img" :src="item.contentThumbnailImage"/>
          <div class="bottom-video">
            <div class="like">
              <img src="@/assets/home/icon_zan.png" alt="">
              <p>{{item.likeNum}}</p>
            </div>
          </div>
        </div>
        <p class="title">{{item.title}}</p>
      </div>

        </van-list>

        <div v-else class="nodata">
            <img src="../../assets/nodata/icon_no_wc.png" alt="">
            <p>暂无内容</p>
        </div>
      </van-pull-refresh>




  </div>

</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import {clearToast, showFailToast, showLoading} from "../../utils/util";
import router from "../../router";

export default {
  name: "myLikes",
  setup() {

    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'page_detail', // 例子
      eventParams: {'page_name': '我的-喜欢','title_name': '我的-喜欢'} ,//例子
      onResponse:  (res)=> {
        console.log("bytedanceApplogEvent responseData:" + res);
      }
    })

    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const data = reactive({
      firstShow:true,
      list:[],
      loadingMore: false,
      loading:false,
      finished:false,
      refreshing:false,
      error:false,
      offset:0,
      limit:10

    })

    const toPlayVideo = (item) => {
      $router.push({path:'/videoPlay',query:{'id':item.helloStarId}})
    }



    const loadData = () => {
      showLoading();
      proxy.$http.get("/wenwu-commodity/product/myLike?offset=" + data.offset + "&limit=" + data.limit).then(res => {
        clearToast()
        if (res.data.code == 200) {
          data.firstShow = false
          clearToast()
          if(data.offset == 0){
            data.list=[];
          }
          data.list.push(...res.data.data);
          data.finished = false
          data.loading = false
          if (res.data.data.length < 10) {
            data.finished = true
          } else {
            data.offset += data.limit
          }
        } else {
          showFailToast(res.data.message)
          data.error = true;
          data.finished = false;
          data.loading = false;
        }
      }).catch((err)=>{
        clearToast()
        showFailToast(err.message)
        data.error = true;
        data.finished = false;
        data.loading = false;
      })
    }

    const onRefresh = (() => {
      data.offset = 0;
      data.finished = true
      data.loading = false
      data.refreshing = false
      loadData()
    })

    const onLoad = (() => {
      loadData()
    })


    const goBack = (() => {
      $router.back(-1)
    })

    return {
      data,
      goBack,
      onLoad,
      onRefresh,
      toPlayVideo

    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


